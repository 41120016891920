const Benefit = ({ props }) => {
    return (
        <div className={`bg-white/30 p-8 relative ${props?.className}`}>
            <span className='absolute -top-8 left-0 translate-x-1/2 rounded-full bg-white inline-flex items-center justify-center text-[#f2aad7] w-16 h-16 text-4xl font-symbol'>{props?.icon}</span>
            <h5>{props.title}</h5>
            {props.description}
        </div>
    )
}

export default Benefit