/**
 * Core
 */
import { useEffect, useState } from 'react'

/**
 * Styles
 */
import  './dropdown.scss'

/**
 * Components
 */
import Select from 'react-select'

const Dropdown = ({ field, errors, callback, innerRef }) => {
    const isMultiple = field.variant == 'multiple'
    const [selectedOption, setSelectedOption] = useState(null)

    useEffect(() => {
        if(selectedOption !== null) callback(isMultiple ? selectedOption.map(o => o.key) : selectedOption.key)
    }, [selectedOption])
    
    return (
        <div className='relative w-full h-full'>
            <div className='pb-2'>
                { field.display_name }
                { field.mandatory && <sup className='text-red-500'>*</sup> }
            </div>
            <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={field.choices.map(choice => { return { ...choice, label:choice.key } })}
                isMulti={isMultiple}
                ref={innerRef}
                classNames={{
                    control: (state) => [`!bg-none !bg-white/15 !rounded-t !border ${errors.length ? '!border-ai-triad-orange-900' : '!border-white/40'} !rounded-none !shadow-none !appearance-none !focus:outline-none !min-h-[3rem]`],
                    menu: (state) => ['!text-ai-gray-900 !bg-none !bg-white !rounded-none !mt-0 !border-0 !shadow-none !rounded-none !z-[20]'],
                    multiValue: (state) => ['!bg-white/30'],
                    singleValue: (state) => ['!text-ai-gray-300'],
                    multiValueRemove: (state) => ['!bg-white/40 hover:!bg-white/80 !text-ai-gray-400 hover:!text-ai-gray-900 !transition !duration-150'],
                    indicatorSeparator: (state) => ['!w-0'],
                    option: (state) => [
                        state.isFocused ? '!bg-gradient-analagous-rose-triad-orange !text-white' : '!bg-white-500',
                        state.isSelected ? '!bg-ai-gray-700 !text-white' : ''
                    ].join(' '),
                  }}
            />
            {
                errors.length ?
                <div className='error-notification mb-8'>
                    {
                        errors.map((error, key) => <p key={key} className='error'>{field.display_name} {error}</p>)
                    }
                </div>
                : <></>
            }
        </div>
    )
}

export default Dropdown