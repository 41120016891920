/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import Bio from '../components/pages/about/bio'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

/**
 * Images
 */
import GaryJackson from '../assets/images/pages/the-judge-club/GaryJackson.jpeg'
import HinaSharma from '../assets/images/pages/the-judge-club/HinaSharma.jpeg'
import JoMayes from '../assets/images/pages/the-judge-club/JoMayes.jpeg'
import RayHarrison from '../assets/images/pages/the-judge-club/RayHarrison.jpeg'
import ValerieHowe from '../assets/images/pages/the-judge-club/ValerieHowe.jpeg'


const TheJudgeClub = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>The Judge Club</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <h4 className='reveal top'>Founded in 2018 The Judge Club helps business professionals  raise their profile and increase business.</h4>

                    <h3 className='pt-16 pb-4 reveal top'>The Judging Experience</h3>

                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8'>
                        <div>
                            <p className='reveal top'>Business people love to judge business awards. Our research tells us there are four main reasons.</p>
                            <ul className='list'>
                                <li className='reveal top'>Helping the finalists and the awards to build a community around positive recognition</li>
                                <li className='reveal top'>Gaining knowledge from reading the entries and hearing first hand about real life initiatives</li>
                                <li className='reveal top'>Building their profile, demonstrating their professionalism and acumen</li>
                                <li className='reveal top'>Networking with like minded men and women; finding support and opportunities and often making long lasting business relationships</li>
                            </ul>
                        </div>
                        <div>
                            <p className='reveal top delay-300'>The experience of the judges, whether it's the scoring process or any aspect of their experience is monitored and developed in conjunction with the customer experience advisory board.</p>
                            <p className='reveal top delay-300'>The Board is comprised individuals who have won and judged many times and from diverse business backgrounds.</p>
                        </div>
                    </div>

                    <h3 className='pt-16 pb-4 reveal top'>Advisory Board</h3>
                    <p className='reveal top'>The customer experience advisory board is made up of longstanding customers; awards judges and entrants. These experienced professionals provide us with feedback on our plans and advises us on making improvements in our customer experience.</p>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-32 mt-32'>
                        {/* <Bio props={{ title: 'Edwin Best', subtitle: 'Best', description: '', image: EdwinBest, className: 'reveal top' }} /> */}
                        <Bio props={{ title: 'Ray Harrison', subtitle: 'FCIPD, FLPI', description: '', image: RayHarrison, className: 'reveal top' }} />
                        <Bio props={{ title: 'Hina Sharma', subtitle: 'Seasoned Communications and Marketing Expert', description: '', image: HinaSharma, className: 'reveal top lg:delay-300' }} />
                        <Bio props={{ title: 'Gary Jackson', subtitle: 'Group Director of Communications & Customer Success', description: '', image: GaryJackson, className: 'reveal top lg:delay-500' }} />
                        <Bio props={{ title: 'Valerie Howe', subtitle: 'Director, Global Marketing & Customer Intelligence', description: '', image: ValerieHowe, className: 'reveal top' }} />
                        <Bio props={{ title: 'Jo Mayes', subtitle: 'Director of Customer Services', description: '', image: JoMayes, className: 'reveal top lg:delay-300' }} />
                    </div>
                </div>
            </section>

            <section className='bg-gray-100 text-ai-gray-900'>
                <div>
                    <h2 className='bg-gradient-analagous-rose-triad-orange self-start reveal top'>Events</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>    
                        <div>
                            <p className='reveal top'>During the year TJC holds events in venue and online. Members share their expertise with each other through workshops on subjects which will help tackle business challenges and opportunities.</p>
                            <p className='reveal top'>There are many networking events, conferences and the awards themselves where judges contribute as speakers, panel hosts or present awards.</p>
                            {/* <p className='reveal top'>Check out the event calendar</p> */}
                            <div className='reveal top'>
                                <button className='dark self-start'>
                                    <Link to={`/events?type=topicSlug&value=the-judge-club`}>
                                        <div>Events</div>
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div>
                            

                            <p className='reveal top'>One of the favourite ways for judges to share their knowledge and experience is via TJC LinkedIn live sessions or as contributors to our media titles CXM and CX Scoop.</p>

                            <div className='reveal top'>
                                <button className='dark self-start'>
                                    <Link to={`/register-interest?interestType=Judge`}>
                                        <div>Apply to judge</div>
                                    </Link>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default TheJudgeClub