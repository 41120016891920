import { useEffect} from 'react'

const ioConfiguration = {
    threshold: 0,
    rootMargin: '-1px 0px -100px 0px'
}

const useScrollTrigger = (selector, options = ioConfiguration) => {
    useEffect(() => {
        let elements = document.querySelectorAll(selector)
        elements = Array.from(elements)
        elements.forEach(element => {
            addObserver(element, options)
        })
    }, [])
}

const addObserver = (element, options) => {
    if(!'IntersectionObserver' in window) {
        if(options.hasOwnProperty('callback')) options.callback(element)
        else element.target.classList.add('active')
        return
    }
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                //console.log(window.innerHeight)
                if(options.hasOwnProperty('callback')) options.callback(element)
                else entry.target.classList.add('active')
                //observer.unobserve(entry.target)
            } else {
                entry.target.classList.remove('active')
            }
        })
    }, options)
    observer.observe(element)
}

export default useScrollTrigger