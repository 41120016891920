import { createContext, useReducer } from 'react'

export const SearchContext = createContext(null)
export const SearchDispatchContext = createContext(null)

export const SearchProvider = ({ children }) => {
    const [variables, dispatch] = useReducer(searchReducer, initalVariables)
    return (
        <SearchContext.Provider value={variables}>
            <SearchDispatchContext.Provider value={dispatch}>
                {children}
            </SearchDispatchContext.Provider>
        </SearchContext.Provider>
    )
}

const searchReducer = (variables, action) => {
    switch(action.type) {
        case 'add': {
            return {
                ...variables,
                [action.dataType]:
                    variables[action.dataType]
                        ? !variables[action.dataType].includes(action.value)
                            ? [ ...variables[action.dataType], action.value ]
                            : variables[action.dataType] 
                        : [ action.value ]
            }
        }
        case 'add-only': {
            return { ...variables, [action.dataType]: action.value }
        }
        case 'none': {
            delete(variables[action.dataType])
            return { ...variables }
        }
        case 'remove': {
            const filtered = variables[action.dataType] ? variables[action.dataType].filter(variable => variable !== action.value) : []
            return filtered.length ? { ...variables, [action.dataType]: filtered } : Object.fromEntries(Object.entries(variables).filter(([key]) => key !== action.dataType))
        }
        case 'init': {
            populateInitialVariables(action)
            return {}
        }
        case 'reset': {
            return {
                [action.dataType]: [ action.value ]
            }
        }
        default: {
            throw Error('Unkonwn action: ' + action.type)
        }
    }
}

let initalVariables = {}

const populateInitialVariables = (action) => {
    if(!initalVariables[action.dataType]) initalVariables[action.dataType] = action.value
}