/**
 * Core
 */
import { useContext, useEffect } from 'react'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_TOPICS } from '../../../utils/gql'

/**
 * Components
 */
import Topic from './topic'
import Spinner from '../top-stories/spinner'

/**
 * Context
 */
import { SearchDispatchContext } from '../../../context/search.context'

const TopicsList = () => {
    const dispatch = useContext(SearchDispatchContext)
    const { data, error, loading } = useQuery(GET_TOPICS, {
        variables: {},
        notifyOnNetworkStatusChange: true,
    })

    useEffect(() => {
        dispatch({
            type: 'init',
            dataType: 'topicSlug',
            value: data?.topics?.nodes?.map(topic => topic.slug)
        })
    }, [data])

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }

    const render = (data) => {
        return (
            <div> 
                {
                    data.topics.nodes.map((topic, key) => 
                        <Topic topic={topic} key={key} />
                    )
                }
            </div>
        )
    }

    return (
        (!data && loading) ? <Spinner /> : render(data)
    )
}

export default TopicsList