const Stats = ({ props }) =>
    <div className={`rounded-xl bg-black p-8 mt-8 max-w-[500px] sm:max-w-auto mx-auto ${props.className}`}>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 mx-auto relative'>
            <div className='stats'><span className='bg-gradient-primary-analagous-pink'>4000</span>Companies shortlisted</div>
            <div className='stats'><span className='bg-gradient-analagous-pink-analagous-rose'>6000</span>Awards recipients</div>
            <div className='stats'><span className='bg-gradient-analagous-rose-triad-orange'>68000</span>Attendees</div>
            <div className='stats'><span className='bg-gradient-triad-orange-split-complementary-yellow'>4000</span>Judges</div>
        </div>
    </div>

export default Stats