/**
 * Compponents
 */
import Footer from '../components/footer'


const Media = () => {
    
    return (
        <>
            <section className='bg-gradient-pastel'>
                <div>
                    <h1>Media</h1>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default Media