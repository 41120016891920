/**
 * Core
 */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Compponents
 */
import Footer from '../components/footer'

const ThankYou = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if(!state) navigate('/')
    }, [])
    return (
        state &&
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Thank You</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 py-16'>
                <div className='' dangerouslySetInnerHTML={{__html: state.message}}></div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default ThankYou