import Footer from "../components/footer"

const NoMatch = () => {
    return (
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>404</h1>
                </div>
            </section>
            <section className="bg-gradient-pastel text-ai-gray-900">
                <div className="">
                    <h3 className="text-center m-0 p-0">The page you are looking for doesn’t exist.</h3>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default NoMatch