import React, { useEffect, useState, cloneElement } from "react"
import { Swiper, SwiperSlide } from 'swiper/react'

import { useSwiper } from "swiper/react"

import "swiper/css"
import "swiper/css/pagination"
import 'swiper/css/navigation'

import { Autoplay, Navigation, Grid } from "swiper/modules"

function Slider({ props, children }) {
    const SwipperControls = ({ children, props }) => <div className={'swiper-controls ' + props.mode}>{children}</div>
    const SwiperButton = ({ children, props }) => {
        const swiper = useSwiper()
        return (
            <button
                className={`swiper-navigate ${props.direction}`}
                onClick={() => {
                    props.direction==='next' ? swiper.slideNext() : swiper.slidePrev()
                }}
                disabled={props.disabled}
            >
                <div>{children}</div>
            </button>
        )
    }
    const [attributes, setAttributes] = useState({
        spaceBetween: 20,
        centeredSlides: false,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        mode: 'dark',
        pagination: {
            clickable: true,
        },
        className: 'max-w-[100%]',
        slideWrapperClassName: '',
        controlled: true,
        breakpoints: {},
        loop: false,
        grid: {},
    })

    const [slideClassName, setSlideClassName] = useState(null)

    const [isFirst, setIsFirst] = useState(true)

    const [previousDisabled, setPreviousDisabled] = useState(false)
    const [nextDisabled, setNextDisabled] = useState(false)

    useEffect(()=>{
        if(isFirst){
            Object.entries(props).map(e => {
                const newAttributes = attributes
                newAttributes[e[0]] = e[1]
                setAttributes(newAttributes)
                //console.log(newAttributes)
            })
            setIsFirst(false)
            if(props.hasOwnProperty('slideClassName')) setSlideClassName(props.slideClassName)
        }
    }, [])

    return(
        !isFirst &&
        <Swiper
            spaceBetween={attributes.spaceBetween}
            loop={attributes.loop}
            breakpoints={attributes.breakpoints}
            slidesPerView={attributes.slidesPerView}
            centeredSlides={attributes.centeredSlides}
            autoplay={attributes.autoplay}
            pagination={attributes.clickable}
            onBeforeInit={(swiper) => {
                //swiperRef.current = swiper;
                if(swiper.isBeginning) setPreviousDisabled(true)
                if(swiper.isEnd) setNextDisabled(true)
            }}
            onSlideChange={(swiper) => {
                if(swiper.isBeginning) setPreviousDisabled(true); else setPreviousDisabled(false)
                if(swiper.isEnd) setNextDisabled(true); else setNextDisabled(false)
            }}
            modules={[Autoplay, Navigation, Grid]}
            className={attributes.className}
            
        >
            {
                attributes.controlled &&
                <SwipperControls props={{ mode: attributes.mode }}>
                    <SwiperButton props={{"direction": "previous", disabled: previousDisabled}}>chevron_left</SwiperButton>
                    <SwiperButton props={{"direction": "next", disabled: nextDisabled}}>chevron_right</SwiperButton>
                </SwipperControls>
            }
            
            {
                children.map((child, i) => {
                    let klon = null
                    if(slideClassName!==null) {
                        klon = cloneElement(child, {
                            className: `${child.props.className} ${slideClassName}`
                        })
                    }
                    return <SwiperSlide key={i} className={attributes.slideWrapperClassName}>{klon !== null ? klon : child}</SwiperSlide>
                })
            }
        </Swiper>
    )
}

export default Slider
    