/**
 * Core
 */
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import PostSkeleton from '../components/pages/top-stories/post-skeleton'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_POSTS } from '../utils/gql'


const TopStories = () => {

    const { data, loading, error, fetchMore } = useQuery(GET_POSTS, {
        variables: { first: 6, after: null },
        notifyOnNetworkStatusChange: true,
    })

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }

    const loadMore = () => {
        fetchMore({
            variables: { after: data?.posts?.pageInfo?.endCursor, first: 4 },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult
                return {
                    posts: {
                        ...fetchMoreResult.posts,
                        edges: [
                            ...prevResult.posts.edges,
                            ...fetchMoreResult.posts.edges,
                        ],
                    },
                }
            },
        })
    }

    return (
        <>
            <section className='bg-white py-16'>
                <div className='items-center'>
                    <h1 className='bg-gradient-analagous-rose-triad-orange'>Top Stories</h1>
                    <h3 className='hidden text-ai-gray-900 text-center pt-12'>Read stories of our amazing winners and their achievements.</h3>
                </div>
            </section>
            <section className='bg-gray-100 text-ai-gray-900 pt-0'>
                <div>
                    {
                        (!data && loading) ?
                        <>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4'>
                                <PostSkeleton />
                                <PostSkeleton />
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4'>
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                            </div>
                        </> :
                        <>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4'>
                                {
                                    data.posts.edges.map((edge) => edge.node).slice(0,2).map((post, i) => {
                                        return (
                                            <div key={ post.id } className='bg-white'>
                                                <img src={ post?.featuredImage?.node?.sourceUrl } className='object-cover w-full h-72' alt=''/>
                                                <h5 className='text-xl px-4 pt-4 leading-6'><Link to={`/top-story/${post?.slug}`}>{ post?.title }</Link></h5>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4'>
                                {
                                    data.posts.edges.map((edge) => edge.node).slice(2).map((post, i) => {
                                        return (
                                            <div key={ post.id } className='bg-white'>
                                                <img src={ post?.featuredImage?.node?.sourceUrl } className='object-cover w-full h-36' alt=''/>
                                                <h5 className='text-xl px-4 pt-4 leading-6'><Link to={`/top-story/${post?.slug}`}>{ post?.title }</Link></h5>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                data?.posts?.pageInfo?.hasNextPage && <button className='dark self-center' onClick={loadMore}><div>Load more</div></button>
                            }
                        </>
                    }
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default TopStories