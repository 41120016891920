/**
 * Core
 */
import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import { ReactComponent as Logo } from '../assets/images/components/menu/logo-f.svg'

/**
 * Context
 */
import { NavigationContext } from '../context/navigation.context'

/**
 * Hooks
 */
import useOutsideClick from '../hooks/outside-click.hook'

/**
 * Utils
 */
import { publish } from '../utils/event'
import items from '../config/menu'

const Menu = () => {

    const { location } = useContext(NavigationContext)
    const bigLogoHeight = 'h-36'

    const onFormTriggered = () => {
        publish('formTriggered', { slug: 'register-interest', openIn: 'page' })
    }

    

    const handleClickOutside = () => {
        setMenuOpen(false)
    }

    const [taglineTransform, setTaglineTransform] = useState(null)
    const [headerTransform, setHeaderTransform] = useState(null)
    const ref = useOutsideClick(handleClickOutside)
    const [menuOpen, setMenuOpen] = useState(false)
    useEffect(() => {
        window.removeEventListener("scroll", listenToScroll)
        setTaglineTransform('opacity-0 text-xs')
        setHeaderTransform('h-24')
        /* if(location.pathname == '/') {
            window.addEventListener("scroll", listenToScroll)
            setTaglineTransform('opacity-100 text-xl')
            setHeaderTransform(bigLogoHeight)
        }
        else {
            window.removeEventListener("scroll", listenToScroll)
            setTaglineTransform('opacity-0 text-xs')
            setHeaderTransform('h-24')
        } */
        return () => 
           window.removeEventListener("scroll", listenToScroll)
    }, [location])

    const listenToScroll = () => {
        let heightToHideFrom = 0
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        if (winScroll > heightToHideFrom) {  
                setTaglineTransform('opacity-0 text-xs')
                setHeaderTransform('h-24')
        } else {
                setTaglineTransform('opacity-100 text-xl')
                setHeaderTransform(bigLogoHeight)
        }  
    }

    return (
       <header className={headerTransform + ' transition-all duration-1000 w-full fixed top-0 z-[2000] bg-ai-gray-900'}>
           <div className='flex justify-between container w-full px-4 mx-auto sm:px-16 xl:px-0 items-center h-full relative bg-ai-gray-900 relative z-[2]'>
                <div className="h-full relative bg-ai-gray-900 z-[202]">
                    <Link to="/" className='flex flex-col items-end h-full relative py-4'>
                        <Logo className='h-full' />
                    </Link>
                </div>
                <div className='xl:hidden grow h-full bg-ai-gray-900 relative z-[201]'></div>
                <div className='flex items-center h-full relative z-[200]'>
                    <div className="h-full">
                        <ul className='flex text-ai-gray-300 items-center h-full'>
                            <div className='hidden xl:flex h-full items-center relative z-[21] bg-ai-gray-900'>
                                {
                                    items.slice(0,5).map((item, key) => 
                                        <li key={key} className='relative ml-8 transition-all duration-300 border-b border-b-transparent hover:text-white after:bg-gradient-primary-analagous-pink after:left-0 after:absolute after:w-0 after:content-[""] after:h-[1px] after:-bottom-2 hover:after:w-full after:transition-all after:duration-150' >
                                            <Link to={item.link}>{item.title}</Link>
                                        </li>
                                    )
                                }
                            </div>
                            <li className='relative group block h-full items-center flex z-[11]' ref={ref}>
                                {
                                    items.length > 5 ?
                                    <>
                                        <div onClick={() => setMenuOpen(!menuOpen)} className='cursor-pointer text-3xl pl-4 font-symbol relative h-full items-center bg-ai-gray-900 flex z-[9]'>more_vert</div>
                                        <ul className='absolute top-0 right-0 pt-24 w-auto h-full z-[1]'>
                                            <div className={`hidden xl:block bg-ai-gray-900 border-ai-gray-800 border-l border-b rounded-b-2xl border-r p-8 py-4 transition duration-300 ${menuOpen ? 'translate-y-0' : '-translate-y-full' } text-nowrap`}>
                                                {
                                                    items.slice(5).map((item, key) => 
                                                        <li onClick={() => setMenuOpen(!menuOpen)} key={key} className='relative transition-all duration-300 border-l border-l-transparent hover:text-white after:bg-gradient-primary-analagous-pink after:bottom-0 after:absolute my-2 after:w-[2px] after:content-[""] after:h-0 after:-left-4 hover:after:h-full after:transition-all after:duration-300'>
                                                            <Link to={item.link}>{item.title}</Link>
                                                        </li>
                                                    )
                                                }
                                            </div>
                                        </ul>
                                    </> :
                                    <>
                                        <div onClick={() => setMenuOpen(!menuOpen)} className='cursor-pointer text-3xl pl-4 font-symbol relative h-full items-center bg-ai-gray-900 flex z-[9]'>more_vert</div>
                                        <ul className='absolute top-0 right-0 pt-24 w-auto h-full z-[1]'>
                                            <div className={`hidden xl:hidden bg-ai-gray-900 border-ai-gray-800 border-l border-b rounded-b-2xl border-r p-8 py-4 transition duration-300 ${menuOpen ? 'translate-y-0' : '-translate-y-full' } text-nowrap`}>
                                                {
                                                    items.slice(5).map((item, key) => 
                                                        <li onClick={() => setMenuOpen(!menuOpen)} key={key} className='relative transition-all duration-300 border-l border-l-transparent hover:text-white after:bg-gradient-primary-analagous-pink after:bottom-0 after:absolute my-2 after:w-[2px] after:content-[""] after:h-0 after:-left-4 hover:after:h-full after:transition-all after:duration-300'>
                                                            <Link to={item.link}>{item.title}</Link>
                                                        </li>
                                                    )
                                                }
                                            </div>
                                        </ul>
                                    </>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="ml-8 hidden xl:block">
                        <button className='!mt-0' onClick={onFormTriggered}><div className='!py-1 !px-4'>Register Interest</div></button>
                    </div>
                </div>
            </div>
            <ul className={`fixed top-0 right-0 pt-24 w-auto h-full z-[1] transition duration-300 ${menuOpen ? 'translate-y-0' : '-translate-y-full'} `}>
                <div className={`xl:hidden rounded-bl-2xl bg-ai-gray-900 border-ai-gray-800 border-l border-b p-8 py-4 text-nowrap`}>
                    { items.map((item, key) => <li key={key} className='py-1'><Link to={item.link}>{item.title}</Link></li>) }
                    <button className='!my-4' onClick={onFormTriggered}><div className='!py-1 !px-4'>Register Interest</div></button>
                </div>
            </ul>
       </header> 
    )
}


export default Menu
