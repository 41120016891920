import Markdown from 'react-markdown'
import { publish } from '../utils/event'

const renderFormField = (field, key) => {
    switch(field.tag) {
        case 'input': return <input key={`field-${key}`} type={field.type} name={field.name} value={field.value} placeholder={field.placeholder} />
        case 'checkbox':
            return (
                <span key={`field-${key}`} className="checkbox">
                    <input type={field.tag} name={field.name} />
                    <label for={field.name}>{field.value}</label>
                </span>
            )
        case 'checkbox-group':
            return (
                <div key={`field-${key}`}>
                    {
                        field.fields.map(subfield => 
                            <div className="flex items-center">
                                <input type={subfield.type} name={subfield.name} value={subfield.value} placeholder={subfield.placeholder} />
                                {
                                    subfield.type === 'checkbox'
                                    ? <label for={subfield.name}>{subfield.label}</label>
                                    : <></>
                                }
                            </div>
                        )
                    }
                </div>
            )
        case 'select': return <div key={`field-${key}`}></div>
        case 'button': return <div key={`field-${key}`}></div>
        case 'html':
            return (
                <div key={`field-${key}`} className="form-style">
                    <Markdown>{field.value}</Markdown>
                </div>                            
            )
        default: <></>
    }
}
const handleFormClosed = () => {
    publish('formClosed')
}

const Form = ({ formData }) => {
    let condition = formData != null && formData.action !== null
    return (
        <>
            {
                condition && <div className='form-wrapper'></div>
            }
            <div className={`form ${condition ? 'active' : ''}`}>
                {
                    (condition) ?
                    <div className='flex flex-col p-8 px-16'>
                        <span className='close' onClick={handleFormClosed}>close</span>
                        <h3 className="text-transparent bg-gradient-full bg-clip-text mb-16 self-start">{formData.name}</h3>
                        <form action={formData.action} method="post" acceptCharset='UTF-8' encType='multipart/form-data'>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 md:gap-x-16 gap-y-4">
                                { formData.FormField.map((field, index) => renderFormField(field, index) ) }
                            </div>
                            <div>
                                <button type="submit">
                                    <div>Submit</div>
                                </button>
                            </div>
                        </form>
                    </div> : <></>
                }
            </div>
        </>          
    )
}

export default Form
export { renderFormField, handleFormClosed }