/**
 * Core
 */

import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */

import Footer from '../components/footer'
import VerticalStepper from '../components/vertical-stepper'
import Mailto from '../components/mailto'

/**
 * Hooks
 */

import useScrollTrigger from '../hooks/scroll-trigger.hook'


const GetStarted = () => {
    const enter = useRef(null)
    const sponsor = useRef(null)
    const partner = useRef(null)
    const judge = useRef(null)
    const started = useRef(null)

    const [active, setActive] = useState('Enter')
    
    useScrollTrigger('.reveal')
    
        
    return(
        <>
            <section className='bg-white pb-16 mt-24 scroll-mt-36' ref={started}>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Get started</h1>
                </div>
            </section>
            <section className="fixed z-[2000] w-full top-24 p-0 border-t border-t-ai-gray-800 text-base lg:text-xl">
                <div className="flex flex-row w-full items-center justify-between lg:h-12">
                    <span onClick={() => { setActive('Enter'); setTimeout(() => { started.current.scrollIntoView({ behavior: "smooth" }); }, 1) } } className="cursor-pointer">Enter</span>
                    <span onClick={() => { setActive('Sponsor'); setTimeout(() => { sponsor.current.scrollIntoView({ behavior: "smooth" }); }, 1) } } className="cursor-pointer">Sponsor</span>
                    <span onClick={() => { setActive('Partner'); setTimeout(() => { partner.current.scrollIntoView({ behavior: "smooth" }); }, 1) } } className="cursor-pointer">Partner</span>
                    <span onClick={() => { setActive('Judge'); setTimeout(() => { judge.current.scrollIntoView({ behavior: "smooth" }); }, 1) } } className="cursor-pointer">Apply to Judge</span>
                </div>
            </section>
            
            {/*
              Enter  
            */}
            <div ref={enter} className={'scroll-mt-36'}>
                <section className="bg-gradient-pastel text-ai-gray-900 pt-0">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8">
                        <aside className="self-start lg:sticky top-0 lg:top-16 lg:col-span-1">
                            <div className="w-full h-32"></div>
                            <h3>How to enter</h3>
                            <p>
                                Entering an Awards International award may be one of the best decisions you ever made. The following summarises what’s involved.
                            </p>
                        </aside>
                        
                        <main className="lg:col-span-2 mt-24">
                            <VerticalStepper props={{
                                steps: [
                                    { mark: '1', title: 'Choose which awards and which categories.', content: '<p>The first step on this exciting journey is to decide which awards you’re going to enter and which categories your entry fits best. You can speak to one of our consultants to help make the decision or download the Plan2Win which gives you all the information you will need.</p>' },
                                    { mark: '2', title: 'Submit the entries.', content: '<p>The entry form is available once you have signed up. Complete the entry form online and once you’ve clicked submit you’re well on the way.</p><p>The Plan2Win contains many tips on writing the entry. There are numerous webinars to help you write the best entry, with award winning experts.</p>' },
                                    { mark: '3', title: 'Make your presentation to the judges.', content: '<p>When your entry is shortlisted you will present to a panel of up to five judges. This normally takes place online via the Awards International website. The judges are not only experts in their field, they’re also provided with guidance and support during their journey to ensure they’re fully prepared. This presentation is delivered with the judges in person. It’s very important the judges get to ask you questions directly.</p><p>Top tip. Never present to the judges alone. It’s natural for them to want to see 	as many of the people involved as possible!</p><p>There are webinars with experts to help you prepare your presentation too. Watch out for the communication or check out (link)</p>' },
                                    { mark: '4', title: 'Find out if you won', content: '<p>There’s nothing like attending the awards ceremony with your team and your colleagues. There is suspense and excitement during the Build up to the Awards. Then there is the glamour and celebration of the gala dinner and awards ceremony, with five star treatment, entertainment and networking. </p><p>In some cases the ceremony is online via our unique platform. It’s amazing to see the teams gathered from all over the country or the world, depending on which awards. </p><p>At the international events, estimates show that there are often thousands of guests online from as many as 50 countries at any one time. Watch teams from Manila to Milwaukee and Manchester to Mumbai as they learn of their win and share their delight in person on screen. It’s a truly remarkable moment to be a part of.</p>' },
                                ]
                            }} />
                        </main>
                    </div>
                    <div>
                        <p>To find out more about entering</p>
                        <div className='reveal top'>
                            <button className='self-start dark'>
                                <Link to={'/register-interest?interestType=Enter'}>
                                    <div>Register Interest</div>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <div ref={sponsor} className={'scroll-mt-36'}>
                <section className="bg-gray-100 text-ai-gray-900">
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Sponsor</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pt-8">
                            <p className="reveal top">Winners will remember the day they won their award for the rest of their lives. The guests at the awards include decision makers and key members of the community. Sponsoring puts you in the best place to be, with someone on one of the most memorable days in their life.</p>
                            <p className="reveal top delay-300">To find out more about the range of sponsorship packages register your interest and a dedicated awards consultant will talk you through the sponsorship packages.</p>
                            <div className='self-start reveal top'>
                                <button className='dark'>
                                    <Link to={'/register-interest?interestType=Sponsor'}>
                                        <div>Register Interest</div>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div ref={partner} className={'scroll-mt-36'}>
                <section className="bg-gradient-pastel text-ai-gray-900">
                    <div>
                        <h2 className="bg-none bg-ai-gray-900 reveal top">Partner with the awards</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pt-8">
                            <div className="">
                                <p className='reveal top'>Becoming a partner of Awards International is a great way to raise your profile and  grow your business.</p>
                                <p className='reveal top'>A partnership entails the exchange of business benefits between two organisations with complementary goals.</p>
                                <ul className="list reveal top">Organisations that become partners are normally:
                                    <li className=''>Institutions whose interest is improving business or an aspect of business such as customer experience.</li>
                                    <li className=''>News and media journals or websites sharing information on a specialist business subject.</li>
                                    <li className=''>Not-for profit organisations interested in business issues, trade, commerce, employees, customers, technology and enterprise.</li>
                                    <li className=''>We may also have commercial partners. For example it may be a benefit to operate one of our events under license in certain parts of the world.</li>
                                </ul>
                                <div className='reveal top'>
                                    <button className='self-start dark'>
                                        <Link to={'/register-interest?interestType=Partner'}>
                                            <div>Register Interest</div>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                            <div className="reveal top delay-300">
                                <h4 className="font-semibold">Case study</h4>
                                <p>Customer Experience Professionals Association. CXPA.</p>

                                <p>Awards International has partnered with for five years with the goal of advancing customer experience globally. Our complementary activities and goals make this a perfect match. CXPA members are offered benefits with Awards International by CXPA leadership. Many employees of Awards International are CXPA members and receive some benefits from that. We advertise each other and support each other at events organised together.</p>

                                <p>If you are interested in partnering with us please get in touch.</p>

                                <p>Contact information</p>
                                <Mailto email="natalija@awardsinternational.com" subject="Hello & Welcome" body="Hello world!" className='mailto'>natalija@awardsinternational.com</Mailto>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div ref={judge} className={'scroll-mt-36'}>
                <section className="bg-none bg-gray-100 text-ai-gray-900">
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Apply to judge</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 pt-8">
                            <div>
                                <h4 className='reveal top font-semibold'>Raise your profile, learn and grow your business, <span className="font-normal">all by judging at the awards!</span></h4>
                                <p className='reveal top'></p>
                            </div>
                            <div>
                                <p className="reveal top delay-300">Leaders and professionals with ten years experience in related business activities can apply to judge at the awards. You’ll join a community of more than a thousand previous judges, many of whom continue to judge after 15 years or even more!</p>
                                <p className="reveal top delay-300">Judges not only get the insights and networking afforded by participation, they can also join the judge club, a unique business club aimed to help you raise your profile and increase your sales.</p>
                            </div>
                        </div>
                        <div className='reveal top'>
                            <button className='self-start dark'>
                                <Link to={'/register-interest?interestType=Judge'}>
                                    <div>Register Interest</div>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
        
    ) 
}

export default GetStarted