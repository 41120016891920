/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Bio from '../components/pages/about/bio'
import Footer from '../components/footer'
import Slider from '../components/slider'
import { TheForm } from './form'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


import AboutPic from '../assets/images/pages/about-us/about-us-pic.jpg'
import don from '../assets/images/pages/about-us/Don.png'
import skehel from '../assets/images/pages/about-us/Neil.png'
import ivana from '../assets/images/pages/about-us/ivana.png'
import borislav from '../assets/images/pages/about-us/borislav.jpg'
import vuk from '../assets/images/pages/about-us/vuk.jpeg'
import greg from '../assets/images/pages/about-us/greg.jpg'
import map from '../assets/images/pages/about-us/map.png'


import homeImg2 from '../assets/images/pages/home/AI-home2.jpg'

/* import Team from '../components/team'; */

const AboutUs = () => {

    useScrollTrigger('.reveal')
    

    return (
        <>
            <section className='py-16'>
                <div>
                    <h1 className="self-start">About us</h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8'>
                        <div>
                            <h4>Awards International specialises in business awards and has ambitious aims to grow to a medium size business by 2030, through organic growth and external investment.</h4>
                            <p>We own the <Link to='https://cxm.co.uk/?utm_source=website&utm_medium=copy&utm_campaign=CXM' target="_blank" rel="noopener noreferrer" className='mailto'>Customer Experience Magazine</Link>, one of the leading CX hubs in the UK and <Link to='https://cxscoop.com/?utm_source=website&utm_medium=copy&utm_campaign=CXScoop' target="_blank" rel="noopener noreferrer" className='mailto'>CX Scoop</Link>, a US focused publication.</p>
                        </div>
                        <div>
                            <p>Awards International maintains a human-centered customer experience. It should never be hard to get hold of a person to help you and you will always get honest and attentive service.</p>
                            <p>We aim to have a simple and transparent relationship with our customers, we aim to give straight answers, fast, have simple to follow terms and conditions, maintain complete transparency on our website of how we operate and never give your data to anyone else without you knowing it.</p>
                        </div>
                    </div>
                </div>
                <div className='max-w-[100vw] mt-16 xl:mt-32'>
                    <Slider props={{
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        autoplay: false,
                        className: 'max-w-[100%]',
                        slideWrapperClassName: 'max-w-[100%] lg:max-w-[1390px] px-0 lg:px-16 2xl:px-0',
                        slideClassName: 'rounded-3xl'
                    }}>
                        <img src='./images/slider/abouttop/1.jpg' alt='' />
                        <img src='./images/slider/abouttop/2.jpg' alt='' />
                        <img src='./images/slider/abouttop/3.jpg' alt='' />
                    </Slider>
                </div>
            </section>

            <section className='bg-gradient-pastel text-ai-gray-900'>
                <div>
                    <h3 className='reveal top'>Awards International was founded in 2008 by Don Hales and Neil Skehel.</h3>

                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-32 mt-32'>
                        <Bio props={{ title: 'Don Hales, 1942-2021.', subtitle: 'Chairman and Founder <br/> Awards International 2008 to 2020.', description: '<p>Don Hales was the founder of many of the biggest British B2B awards and is often referred to as The Don of the awards world.</p><p>Don was deputy MD of Quest Media (now UBM, now part of Informa), and as such founder of the National Sales awards and the National Customer Service Awards, which were two of the biggest B2B awards in the UK. Don founded and chaired many other business awards and met Neil Skehel at the British Computer Society Awards in 2006.</p>', image: don, className: 'reveal top' }} />
                        <Bio props={{ title: 'Neil Skehel', subtitle: 'CEO and Founder <br/> Awards International 2008 to the present.', description: '<p>Neil Skehel worked for McDonalds UK for twenty years. He started as crew in Brighton in 1985 and when he left in 2006 was Head of National Operations. He had a reputation for innovation and operational excellence.</p><p>Between 2006 and 2013 Neil Skehel was a consultant in FMCG for a number of large organisations including BP, Shell and Barclays.</p>', image: skehel, className: 'reveal top delay-300' }} />
                    </div>

                    <div className='bg-white/30 p-8 mt-8 reveal top'>
                        <p>Following the BCS Awards Don and Neil became friends and Neil judged at several awards for Don in the following years, gaining valuable insight into the best awards events.</p>
                        <p>Awards International now has 30 or so employees and customers on five continents, with offices in London, Serbia, the USA, Dubai and Cyprus.</p>
                        <p>Awards International operates Customer Experience Magazine, whose target is the UK market and CXScoop in the USA.</p>
                    </div>
                </div>
            </section>

            <section className='bg-gray-100 text-ai-gray-900'>
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">The DreamTeam</h2>
                    <h4 className='font-semibold reveal top'>The Awards International team refers to itself as the DreamTeam.</h4>
                    <p className='reveal top'>Everyone on the team aspires to deliver a customer experience others only dream about. By the same token, the Company aspires to offer its team career opportunities second to none.</p>
                    <h3 className='font-semibold reveal top pt-16'>Management Team</h3>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-32 mt-32'>
                        <Bio props={{ title: 'Ivana Kircanski', subtitle: 'COO, Director Awards International Ltd', description: '', image: ivana, className: 'reveal top !bg-white', link: { uri: 'https://www.linkedin.com/in/kircanski/', text: 'Linkedin' } }} />
                        <Bio props={{ title: 'Boris Lojpur', subtitle: 'MD, Awards International DOO', description: '', image: borislav, className: 'reveal top sm:delay-300 !bg-white', link: { uri: 'https://www.linkedin.com/in/lojpurborislav/', text: 'Linkedin' } }} />
                        <Bio props={{ title: 'Greg Wilson', subtitle: 'Chief Editor', description: '', image: greg, className: 'reveal top xl:delay-500 !bg-white', link: { uri: 'https://www.linkedin.com/in/greg-wilson-66130944/', text: 'Linkedin' } }}  />
                        <Bio props={{ title: 'Vuk Vukanovic', subtitle: 'Sales Manager', description: '', image: vuk, className: 'reveal top sm:delay-300 xl:delay-700 !bg-white', link: { uri: 'https://www.linkedin.com/in/vukvukanovic/', text: 'Linkedin' } }} />
                    </div>
                </div>
            </section>

            <section>
                <div>
                    <h2 className='reveal top self-start'>Careers with us</h2>
                    <p className='reveal top'>We are always interested in hearing from people with a passion for awards and the customer experience. We may not be recruiting at the moment, but we would be happy to discuss any mutually beneficial opportunities should you wish. </p>
                    <div className='my-16 reveal top'>
                        <TheForm slug="career-with-us" colorMode='dark' />
                    </div>
                    {/* <div className='reveal top'>
                        <button className='self-start'><div>Contact us</div></button>
                    </div> */}
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default AboutUs