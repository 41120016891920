/**
 * Core
 */
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import RegionsList from '../components/pages/events/regions-list'
import TopicsList from '../components/pages/events/topics-list'
import EventTypesList from '../components/pages/events/event-types-list'
import PostSkeleton from '../components/pages/top-stories/post-skeleton'
//import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { atcb_action } from "add-to-calendar-button"

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { FILTER_EVENTS } from '../utils/gql'

/**
 * Context
 */
import { SearchContext, SearchDispatchContext } from '../context/search.context'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const Events = () => {
    
    const dispatch = useContext(SearchDispatchContext)

    const [searchParams] = useSearchParams()
    const type = searchParams.get('type') || null
    const value = searchParams.get('value') || null

    useEffect(() => {
        if(type !== null && value !== null) dispatch({
            type: 'reset',
            dataType: type,
            value
        })
    }, [type, value])

    
        
    return (
        <EventsMain />
    )
}

const EventsMain = () => {
    const [filtersShown, setFiltersShown] = useState(false)
    const variables = useContext(SearchContext)
    
    useScrollTrigger('.reveal')

    const { data, error, loading } = useQuery(FILTER_EVENTS, {
        variables,
        notifyOnNetworkStatusChange: true,
        first: 1000
    })

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }

    const randomImages = [
        'https://blog.juliedesk.com/wp-content/uploads/2017/06/1200x600-default-facebook.png',
        'https://static.zerochan.net/Sakimori.full.1626278.jpg',
        'https://media.kasperskycontenthub.com/wp-content/uploads/sites/43/2022/04/25092934/abstract-data-waves-storm-sl-1200x600.jpg',
        'https://www.teahub.io/photos/full/276-2762871_desktop-background-images-hd.jpg',
        'https://www.eba250.com/wp-content/uploads/2019/03/eba250-slider-918402766-1200x600.jpg',
        'https://www.teahub.io/photos/full/276-2762658_4k-ultra-hd-wallpapers-for-pc.jpg',
        'https://images.ctfassets.net/ao073xfdpkqn/S3DND1wzeiqpgnHqToCtX/7957f679a92e8fc7eca4f93fe17663c7/fast-blurred-headlights-1200x600.jpg'
    ]

    const randomImage = () => randomImages[Math.floor(Math.random() * randomImages.length)]

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const getDate = date => {
        const dd = new Date(date.slice(0,-6))
        const d = dd.getDate()
        const m = dd.getMonth()
        const y = dd.getFullYear()
        return {
            text: `${months[m]} ${d}, ${y}`,
            sql: `${y}-${m<9?'0'+(m+1):(m+1)}-${d<10?'0'+d:d}`,
        }
    }

    const getEvents = (data) => {
        let events = []
        data.map( event => {
            const obj = {
                title: event.title,
                events: event.events,
                eventtypes: event.eventtypes,
                regions: event.regions,
                topics: event.topics,
                keyDates: [],
                sortableKeydate: null
            }
            if(event.events.keyDates.keyDate !== null) obj.keyDates.push({date: event.events.keyDates.keyDate, label: event.events.keyDates.keyDateLabel})
            if(event.events.keyDates.keyDate1 !== null) obj.keyDates.push({date: event.events.keyDates.keyDate1, label: event.events.keyDates.keyDateLabel1})
            if(event.events.keyDates.keyDate2 !== null) obj.keyDates.push({date: event.events.keyDates.keyDate2, label: event.events.keyDates.keyDateLabel2})
            obj.keyDates.sort((date1, date2) =>  date1.date > date2.date ? 1 : -1)
            obj.sortableKeydate = obj.keyDates.find(keydate => {
                const d = new Date(keydate.date)
                return d > Date.now() ? keydate : null
            })
            events.push(obj)
        })
        events = [
            ...events.filter(event => event.sortableKeydate).sort((a,b) => a.sortableKeydate.date > b.sortableKeydate.date ? 1 : -1),
            ...(events.filter(event => !event.sortableKeydate))
        ]
        return events
    }

    const isLink = (link) => {
        const expression = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gi
        const regex = new RegExp(expression)
        return link ? link.match(regex) ? true : false : false
    }

    const handleAddToCalendar = (e, event, whichDate) => {
        console.log(getDate(event.events.keyDates['keyDate'+whichDate]).sql)
        const config = {
            name: event.title,
            description: event.events.keyDates['keyDateLabel'+whichDate] + (isLink(event.events.joinUs) ? "[url]" + event.events.joinUs + "|[br]Find out more[/url]" : ''),
            startDate: getDate(event.events.keyDates['keyDate'+whichDate]).sql,
            //startTime: "10:15",
            //endTime: "23:30",
            options: [
                'Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'
            ],
            //timeZone: "America/Los_Angeles"
        }
        atcb_action(config, e.target)
        //console.log(e.target)
        /* name={event.title}
        startDate={getDate(event.events.keyDates.keyDate).sql}
        options={['Apple','Google','Yahoo','iCal']} */
    }

    return (
        <div>
            <section className='bg-white py-16 relative z-[11]'>
                <div className='items-center'>
                    <h1 className='bg-gradient-analagous-rose-triad-orange'>Events</h1>
                    <h4 className='hidden text-ai-gray-900 text-center pt-12'>We organise many events throughout the year including awards. Use the filter below to find something that will help you with your business.</h4>
                </div>
            </section>
            <section className='pt-0 bg-gray-100 text-ai-gray-900 relative z-[100]'>
                <div className='sticky top-24 z-[10099] bg-white py-4 !min-w-[100%]'>
                    <div onClick={() => { setFiltersShown(!filtersShown) }} className='cursor-pointer container mx-auto flex items-center font-semibold text-2xl'>Filters <span className='ml-4 font-symbol font-normal text-4xl'>instant_mix</span></div>
                    <div className={`container mx-auto transition-[grid-template-rows] duration-300 grid ${filtersShown ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
                        <div className='overflow-hidden relative z-[1]'>
                            <div className={`grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-4 p-4`}>
                                <RegionsList />
                                <TopicsList />                      
                                <EventTypesList />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:min-h-[100vh] relative z-[10000]'>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-8">
                        {
                            (!data && loading) ?
                            <>
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                            </> :
                            getEvents(data.events.nodes).map((event, key) => {
                                return (
                                    <div key={key} className={`rounded-2xl bg-white text-ai-gray-900 ${key%2 ? 'delay-300' : ''}`}>
                                        <div className='relative pb-[50%]'>
                                            {
                                                isLink(event.events.joinUs) ?
                                                <a href={event.events.joinUs} className='absolute z-10 w-full h-full block rounded-t-2xl' target='_blank'>
                                                    <img className='absolute w-full h-full rounded-t-2xl object-cover' src={event.events.banner ? event.events.banner.node.sourceUrl : randomImage()} />
                                                    {
                                                        event.events.logo &&
                                                        <div className='absolute left-4 xl:left-8 z-20 bottom-0 translate-y-1/2 w-24 xl:w-36 p-4 xl:p-6 bg-white rounded-full shadow-[inset_0_-3px_18px_rgba(0,0,0,0.1)]'>
                                                            <img src={event.events.logo?.node?.sourceUrl} className='w-full h-full object-cover' />
                                                        </div>
                                                    }
                                                </a> :
                                                <>
                                                    <img className='absolute w-full h-full rounded-t-2xl object-cover' src={event.events.banner ? event.events.banner.node.sourceUrl : randomImage()} />
                                                    {
                                                        event.events.logo &&
                                                        <div className='absolute left-4 xl:left-8 z-20 bottom-0 translate-y-1/2 w-24 xl:w-36 p-4 xl:p-6 bg-white rounded-full shadow-[inset_0_-3px_18px_rgba(0,0,0,0.1)]'>
                                                            <img src={event.events.logo?.node?.sourceUrl} className='w-full h-full object-cover' />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className='relative p-6 pb-2 xl:px-12 xl:py-8 xl:pb-0 flex flex-col'>
                                                <div className='self-end font-light border-b border-b-gray-200'>
                                                    {event.topics.nodes.map(topic => topic.name)}
                                                </div>
                                                <div className='self-end font-light'>
                                                    {event.regions.nodes.map(region => region.name)}
                                                </div>
                                                {
                                                    isLink(event.events.joinUs) ?
                                                    <a href={event.events.joinUs} className='' target='_blank'>
                                                        <h4 className='font-semibold pt-6 xl:pt-12 bg-clip-text text-transparent bg-gradient-analagous-rose-triad-orange self-start'>{event.title}</h4>
                                                    </a>
                                                    :
                                                    <h4 className='font-semibold pt-6 xl:pt-12 bg-clip-text text-transparent bg-gradient-analagous-rose-triad-orange self-start'>{event.title}</h4>
                                                }
                                                <div className='flex items-center py-4 font-semibold'>
                                                    <span className='font-symbol mr-2 text-ai-primary-500'>location_on</span> {event.events.city ? event.events.city + ', ' : ''}<span className='ml-1 font-normal lowercase'>{event.events.type}</span>
                                                </div>
                                            
                                        </div>
                                        <div className={'p-6 xl:px-12 xl:py-4 xl:pb-6 pt-0'}>
                                            {
                                                event.events.keyDates.keyDate &&
                                                <div className='flex flex-col xl:flex-row xl:justify-between'>
                                                    {event.events.keyDates.keyDateLabel}
                                                    <div className='flex items-center'>
                                                        <span className='font-semibold'>{getDate(event.events.keyDates.keyDate).text}</span>
                                                        <span className='ml-2 cursor-pointer font-symbol text-2xl transition duration-150 hover:text-ai-primary-500' onClick={(e) => handleAddToCalendar(e, event, '')}>calendar_add_on</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                event.events.keyDates.keyDate1 &&
                                                <div className='flex flex-col xl:flex-row xl:justify-between'>
                                                    {event.events.keyDates.keyDateLabel1}
                                                    <div className='flex items-center'>
                                                        <span className='font-semibold'>{getDate(event.events.keyDates.keyDate1).text}</span>
                                                        <span className='ml-2 cursor-pointer font-symbol text-2xl transition duration-150 hover:text-ai-primary-500' onClick={(e) => handleAddToCalendar(e, event, '1')}>calendar_add_on</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                event.events.keyDates.keyDate2 &&
                                                <div className='flex flex-col xl:flex-row xl:justify-between'>
                                                    {event.events.keyDates.keyDateLabel2}
                                                    <div className='flex items-center'>
                                                        <span className='font-semibold'>{getDate(event.events.keyDates.keyDate2).text}</span>
                                                        <span className='ml-2 cursor-pointer font-symbol text-2xl transition duration-150 hover:text-ai-primary-500' onClick={(e) => handleAddToCalendar(e, event, '2')}>calendar_add_on</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </div>
    )
}

export default Events