/**
 * Core
 */
import { useContext, useEffect } from 'react'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_EVENTTYPES } from '../../../utils/gql'

/**
 * Components
 */
import EventType from './event-type'
import Spinner from '../top-stories/spinner'

/**
 * Context
 */
import { SearchDispatchContext } from '../../../context/search.context'

const EventTypesList = () => {
    const dispatch = useContext(SearchDispatchContext)
    const { data, error, loading } = useQuery(GET_EVENTTYPES, {
        variables: {},
        notifyOnNetworkStatusChange: true,
    })

    useEffect(() => {
        dispatch({
            type: 'init',
            dataType: 'eventtypesSlug',
            value: data?.eventtypes?.nodes?.map(eventType => eventType.slug)
        })
    }, [data])

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }

    const render = (data) => {
        return (
            <div> 
                {
                    data.eventtypes.nodes.map((eventType, key) => 
                        <EventType eventType={eventType} key={key} />
                    )
                }
            </div>
        )
    }

    return (
        (!data && loading) ? <Spinner /> : render(data)
    )
}

export default EventTypesList