/**
 * Core
 */
import { useContext, useEffect } from 'react'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_REGIONS } from '../../../utils/gql'

/**
 * Components
 */
import Region from './region'
import Spinner from '../top-stories/spinner'

/**
 * Context
 */
import { SearchDispatchContext } from '../../../context/search.context'

const RegionsList = () => {
    const dispatch = useContext(SearchDispatchContext)
    const { data, error, loading } = useQuery(GET_REGIONS, {
        variables: {},
        notifyOnNetworkStatusChange: true,
    })

    useEffect(() => {
        dispatch({
            type: 'init',
            dataType: 'regionSlug',
            value: data?.regions?.nodes?.map(region => region.slug)
        })
    }, [data])

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }

    const render = (data) => {
        return (
            <div> 
                {
                    data.regions.nodes.map((region, key) => 
                        <Region region={region} key={key} />
                    )
                }
            </div>
        )
    }

    return (
        (!data && loading) ? <Spinner /> : render(data)
    )
}

export default RegionsList