/**
 * Core
 */
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

/**
 * Components
 */
import Dropdown from '../components/dropdown/dropdown'
import Footer from '../components/footer'

/**
 * Utils
 */
import forms from '../config/forms'


const TheForm = ({ slug, colorMode = "" }) => {
    const formData = forms.find(form => form.slug == slug)
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [firstActions, setFirstActions] = useState([])
    const [validForm, setValidForm] = useState(false)

    const elements = useRef([])

    const [searchParams] = useSearchParams()
    
    const initData = async () => {
        const initial = {}
        const initialErrors = {}
        const initialFirstActions = {}
        formData.definition.fields.map(field => {
            switch(field.type) {
                case 'input':
                    switch(field.variant) {
                        case 'group':
                            initial[field.key] = {}
                            field.fields.map(f => {
                                initial[field.key][f.key] = null
                                initialErrors[f.key] = []
                                initialFirstActions[f.key] = true
                            })
                            break
                        case 'checkbox':
                            initial[field.key] = JSON.stringify(false)
                            initialErrors[field.key] = []
                            initialFirstActions[field.key] = true
                            break
                        case 'hidden': 
                            initial[field.key] = field.initialValue
                            break
                        default: 
                            initial[field.key] = null
                            initialErrors[field.key] = []
                            initialFirstActions[field.key] = true
                            break
                    }
                    break
                case 'select':
                    switch(field.variant) {
                        case 'multiple':
                            initial[field.key] = []
                            initialErrors[field.key] = []
                            initialFirstActions[field.key] = true
                            break
                        default:
                            initial[field.key] = null
                            initialErrors[field.key] = []
                            initialFirstActions[field.key] = true
                            break
                    }
            }
        })
        setData(initial)
        setErrors(initialErrors)
        setFirstActions(initialFirstActions)
    }
    
    useEffect(() => {
        if( formData == null) navigate('/')
        const init = async () => {
            await initData()
        }
        init()
        searchParams.forEach((value, key) => {
            // HARD - only select !
            elements.current[key].selectOption({label: value})
        })
        
    }, [])

    useEffect(() => {
        //console.log(errors)
        isValidForm()
    }, [errors, firstActions])

    const onChange = (field, value, isGroup = false, groupKey = null) => {
        if(firstActions[field.key]) setFirstActions({...firstActions, [field.key]: false})
        if(!isGroup) setData({ ...data, [field.key]: value })
        else setData({ ...data, [groupKey]: { ...data[groupKey], [field.key]: value } })
        if(field.hasOwnProperty('mandatory') && field.mandatory) {
            validate(value, field)
            isValidForm()
        }
    }

    const validate = (value, field) => {
        const err = []
        field.validation.map(rule => {
            switch(rule.type) {
                case 'notEmpty':
                    if( (Array.isArray(value) || (typeof value === 'string' || value instanceof String) ) && !value.length) {
                        err.push(rule.message)
                    }
                    break
                case 'minLength':
                    if ( (typeof value === 'string' || value instanceof String) && value.length < rule.value ) {
                        err.push(rule.message)
                    }
                    break
                case 'maxLength':
                    if ( (typeof value === 'string' || value instanceof String) && value.length > rule.value ) {
                        err.push(rule.message)
                    }
                    break
                default:
                    break
            }
        })
        if(err.length) setErrors({...errors, [field.key]: err })
        else {
            const e = {...errors}
            e[field.key] = []
            setErrors(e)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        fetch(formData.formPerma, {
            method: "POST",
            mode: "no-cors",
            credentials: "include", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json", 
            },
            body: JSON.stringify(data)
        })
        .then(() => {
            navigate('/thank-you', { state: { referer: formData.slug, message: formData.thankYouMessage} })
        })
		.catch(err => console.error(err))
    }

    const isError = (key) => {
        return errors[key]?.length ? true : false
    }
    const isValidForm = () => {
        let valid = true
        for(const [key, value] of Object.entries(firstActions)) {
            const field = formData.definition.fields.find(field => field.key == key)
            if(typeof field !== 'undefined' && field.hasOwnProperty('mandatory') && field.mandatory)
                valid &= value ? false : !errors[key].length
        }
       
        setValidForm(!!valid)
    }

    const renderFormField = (field, key) => {
        switch(field.type) {
            case 'input':
                switch(field.variant) {
                    case 'group':
                        const span = 'col-span-1 xl:col-span-2'// + field.fields.length
                        let group = {}
                        return (
                            <div key={key} className={`relative ${span}`}>
                                {/* <div className='pb-2'>
                                    { field.display_name }
                                    { field.mandatory && <sup className='text-red-500'>*</sup> }
                                </div> */}
                                <div className='flex gap-8 w-full'>
                                    {
                                        field.fields.map((f, key) => {
                                            group[f.key] = null
                                            return (
                                                <div key={key} className='grow flex flex-col relative'>
                                                    <div className='pb-2'>{f.display_name} { field.mandatory && <sup className='text-red-500'>*</sup> }</div>
                                                    <input ref={el => elements.current[f.id] = el} className={isError(f.key) ? '!border-ai-triad-orange-900' : ''} key={key} data-group={field.key} type={f.variant} name={f.key} onBlur={(e) => { onChange(f, e.target.value, true, field.key) }} onChange={(e) => onChange(f, e.target.value, true, field.key)}/>
                                                    {
                                                        isError(f.key) ?
                                                        <div className='error-notification'>
                                                            {
                                                                errors[f.key].map((error, key) => <p key={key} className='error'>{f.display_name} {error}</p>)
                                                            }
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    case 'checkbox':
                        return (
                            <div key={key} className='relative flex flex-col'>
                                {
                                    field.description && <div className='text-xl'>{field.description}</div>
                                }
                                <div className='flex gap-10 items-center'>
                                    <input ref={el => elements.current[field.id] = el} type={field.variant} name={field.key} onBlur={(e) => { onChange(field, JSON.stringify(e.target.checked)) }} onChange={(e) => { onChange(field, JSON.stringify(e.target.checked)) }}/>
                                    <label htmlFor={field.key}>{field.choices[0].key} { field.mandatory && <sup className='text-red-500'>*</sup> }</label>
                                </div>
                                {
                                    field.help && <div className='text-base text-ai-gray-700 pl-1 pt-2'>{field.help}</div>
                                }
                            </div>
                        )
                    case 'hidden':
                            return (
                                <div key={key} className='relative flex flex-col hidden'>
                                    <div className='flex gap-10 items-center'>
                                        <input type="text" name={field.key} defaultValue={field.initialValue} />
                                        <label htmlFor={field.key}>{field.key}</label>
                                    </div>
                                </div>
                            )
                    default:
                        return (
                            <div key={key} className='relative flex flex-col'>
                                <div className={`pb-2 ${isError(field.key) ? 'border-ai-triad-orange-900' : ''}`}>
                                    {field.display_name}
                                    { field.mandatory && <sup className='text-red-500'>*</sup> }
                                </div>
                                <input ref={el => elements.current[field.id] = el} type={field.variant} name={field.key} onBlur={(e) => { onChange(field, e.target.value) }} onChange={(e) => { onChange(field, e.target.value) }} className={isError(field.key) ? 'error' : ''} />
                                {
                                    isError(field.key) ?
                                    <div className='error-notification'>
                                        {
                                            errors[field.key].map((error, key) => <p key={key} className='error'>{field.display_name} {error}</p>)
                                        }
                                    </div>
                                    : <></>
                                }
                            </div>
                        )
                }
            case 'select':
                return (
                    <div key={key} className='relative'>
                        <Dropdown innerRef={el => elements.current[field.id] = el} field={field} errors={Object.keys(errors).includes(field.key) ? errors[field.key]: [] } callback={(selected) => onChange(field, selected)} />
                    </div>
                )
            case 'textarea':
                return (
                    <div key={key} className='relative'>
                        <div className={`pb-2 ${isError(field.key) ? 'border-ai-triad-orange-900' : ''}`}>
                            {field.display_name}
                            { field.mandatory && <sup className='text-red-500'>*</sup> }
                        </div>
                        <div className='relative h-48'>
                            <textarea ref={el => elements.current[field.id] = el} className={isError(field.key) ? '!border-ai-triad-orange-900' : ''} key={key} name={field.key} onChange={(e) => onChange(field, e.target.value, true, field.key)}></textarea>
                        </div>
                        {
                            isError(field.key) ?
                            <div className='error-notification'>
                                {
                                    errors[field.key].map((error, key) => <p key={key} className='error'>{field.display_name} {error}</p>)
                                }
                            </div>
                            : <></>
                        }
                    </div>
                )
            default:
        }
    }
    
    return (
        <form method="post" acceptCharset="UTF-8" encType="multipart/form-data" className={`form-page ${colorMode}`}>
            <div className='fields'>
                {
                    formData.definition.fields.map((field, key) => {
                        return (
                            renderFormField(field, key)
                        )
                    })
                }
            </div>
            <div>
                <button type="submit" onClick={handleSubmit} disabled={!validForm}>
                    <div>Submit</div>
                </button>
            </div>
        </form>
    )
}

const FormPage = ({slug}) => {
    const navigate = useNavigate()
    
    const formData = forms.find(form => form.slug == slug)

    return (
        formData == null ? navigate('/') :
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>{formData.name}</h1>
                </div>
            </section>
            <section className="bg-gradient-pastel">
                <div>
                    <TheForm slug={slug} />
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default FormPage
export { TheForm }