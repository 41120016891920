const root = 'https://awardsinternational.com'

const routes = [
    {
        'name': 'Home',
        'path': '/',
        'title': 'Awards International | Home',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/`,
        'og:image:secure_url': `${root}/images/og/home.jpg`
    },
    {
        'name': 'Events',
        'path': '/events',
        'title': 'Awards International | Events',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/events`,
        'og:image:secure_url': `${root}/images/og/events.png`
    },
    {
        'name': 'Get Started',
        'path': '/get-started',
        'title': 'Awards International | Get Started',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/get-started`,
        'og:image:secure_url': `${root}/images/og/get-started.png`
    },
    {
        'name': 'About Us',
        'path': '/about-us',
        'title': 'Awards International | About Us',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/about-us`,
        'og:image:secure_url': `${root}/images/og/about-us.png`
    },
    {
        'name': 'Top Stories',
        'path': '/top-stories',
        'title': 'Awards International | Top Stories',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/top-stories`,
        'og:image:secure_url': `${root}/images/og/top-stories.png`
    },
    {
        'name': 'The Judge Club',
        'path': '/the-judge-club',
        'title': 'Awards International | The Judge Club',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/the-judge-club`,
        'og:image:secure_url': `${root}/images/og/the-judge-club.png`
    },
    {
        'name': 'Privacy Policy',
        'path': '/privacy-policy',
        'title': 'Awards International | Privacy Policy',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/privacy-policy`,
        'og:image:secure_url': `${root}/images/og/privacy-policy.png`
    },
    {
        'name': 'Terms of Use',
        'path': '/terms-of-use',
        'title': 'Awards International | Terms of Use',
        'description': '',
        'og:type': 'website',
        'og:url': `${root}/terms-of-use`,
        'og:image:secure_url': `${root}/images/og/privacy-policy.png`
    }
]

export default routes