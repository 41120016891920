/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Benefit from '../components/pages/home/benefit'
import TopStory from '../components/pages/home/top-story'
import Testimonial from '../components/pages/home/testimonial'
import Announcement from '../components/announcement'
import Stats from '../components/pages/home/stats'
import Slider from '../components/slider'
import Footer from '../components/footer'
import ImageScroller from '../components/image-scroller'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_POSTS } from '../utils/gql'

/**
 * Images
 */
import neil from '../assets/images/pages/home/neil.png'


const Main = () => {
    useScrollTrigger('.reveal')
    const { data, loading } = useQuery(GET_POSTS, {
        variables: { first: 6, after: null },
        notifyOnNetworkStatusChange: true,
    })

    const scrollingLogos = ['allianz.svg', 'amazon.svg', 'barclays.svg', 'bt.svg', 'cisco.svg', 'google.svg', 'hsbc.svg', 'intuit_quickbooks.svg', 'majid_al_futaim.svg', 'microsoft.svg', 'netflix.svg', 'roshn.svg', 'samsung.svg', 'schneider_electric_2.svg', 'shell.svg', 'vodafone.svg', 'volvo.svg', 'pfizer.svg', 'unilever.svg', 'stc.svg', 'gsk.svg', 'coca_cola.svg', 'p_g.svg', 'oracle.svg', 'nestle.svg', 'uber.svg', 'mercedes.svg', 'human_resources_and_social_development.svg']
    return (
        <>
            <section className='pt-16 pb-12 xs:pt-12 xs:pb-8 xl:pt-12 xl:pb-8 2xl:pt-20 2xl:pb-4'>
                <div className='items-center'>
                    <h1><span className='text-4xl block'>The</span> Ultimate Accolade</h1>
                    <h4 className='text-ai-grey-50 pt-0'>Unrivalled rigour, service and professionalism.</h4>
                    <button>
                        <Link to={`/events?type=eventtypeSlug&value=awards`}>
                            <div>Awards listing</div>
                        </Link>
                    </button>
                </div>
            </section>

            <section>
                <div className='max-w-[100vw]'>
                    <Slider props={{
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        autoplay: false,
                        className: 'max-w-[100%]',
                        slideWrapperClassName: 'max-w-[100%] xl:max-w-[1128px] 2xl:max-w-[1390px] px-0 2xl:px-0',
                        slideClassName: 'rounded-3xl'
                    }}>
                        <img src='./images/slider/hometop/1.jpg' alt='' />
                        <img src='./images/slider/hometop/2.jpg' alt='' />
                        <img src='./images/slider/hometop/3.jpg' alt='' />
                        <img src='./images/slider/hometop/4.jpg' alt='' />
                        <img src='./images/slider/hometop/5.jpg' alt='' />
                        <img src='./images/slider/hometop/6.jpg' alt='' />
                    </Slider>
                </div>
            </section>

            <section className='bg-gradient-pastel text-ai-gray-900'>
                <div>
                    <h3 className='reveal top'>When you win, you know it's because you deserve it, you won it by the fairest means possible.</h3>
                    <p className='self-end text-ai-gray-900 reveal top'>Andrew Carothers, Digital Customer Experience Leader, Cisco</p>

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16 mt-16'>
                        <Benefit props={{ title: 'The Awards International name means', description: 'Your award is widely respected and envied by your competitors.', className: 'reveal top', icon: 'trophy' }} />
                        <Benefit props={{ title: 'The ROI on participation in the awards is significant', description: 'Participating in the awards yields a substantial return on investment.', className: 'reveal top sm:delay-300', icon: 'currency_exchange' }} />
                        <Benefit props={{ title: 'Participate in our awards programmes', description: 'To learn and grow individually, as a team and as a business.', className: 'reveal top lg:delay-500', icon: 'groups' }} />
                        <Benefit props={{ title: 'The Awards International name means', description: 'Making business better locally and globally bringing people together from across the globe.', className: 'reveal top sm:max-lg:delay-300', icon: 'public' }} />
                        <Benefit props={{ title: 'Awards International provides', description: 'Independent expert validation for your business initiatives.', className: 'reveal top lg:delay-300', icon: 'verified' }} />
                        <Benefit props={{ title: 'Makes your business more attractive to', description: 'Employee and investor markets.', className: 'reveal top lg:delay-300', icon: 'business_center' }} />
                    </div>

                    <div className='reveal top'>
                        <button className='dark self-start'>
                            <Link to={`/get-started`}>
                                <div>Get started</div>
                            </Link>
                        </button>
                    </div>
                    
                </div>
            </section>

            <section className='relative'>
                <div className='sticky h-screen top-0 z-[7] flex flex-col justify-center lg:px-36'>
                    
                    <h2 className='self-start'>CXA®</h2>
                    
                    <p className='font-medium text-2xl xs:text-3xl lg:text-4xl bg-clip-text text-transparent bg-gradient-full'>The CXA® is a global brand comprised of a number of awards celebrating customer experience excellence around the world. CXA® was founded in 2010 in the UK and was the first and remains the greatest celebration of customer experience on the planet today.</p>
                    <div className='inline-flex'>
                        <button>
                            <Link to={`/events?type=topicSlug&value=customer-experience`}>
                                <div>Browse CXA</div>
                            </Link>
                        </button>
                    </div>
                </div>
                <div className='h-[150vh] min-w-[100%] relative z-[8] -translate-y-[100vh]'>
                    <div className='h-1/2 bg-ai-gray-900'>
                        <div className='container mx-auto h-full grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-0 auto-rows-min'>
                            <div className='flex lg:col-span-2'>
                                <h2 className='self-start reveal top'>Awards<br /> International</h2>
                            </div>
                            <div>
                                <h4 className='text-ai-grey-50 reveal top'>was founded in 2008 and operates 15  awards in different countries.</h4>
                                <div className='reveal top'>
                                    <button>
                                        <Link to={`/about-us`}>
                                            <div>About us</div>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                            <p className='reveal top delay-300'>Awards International operates Customer Experience Awards - The CXA® in 4 continents (Europe, Africa, North America, Asia); the International Business Excellence Awards; the UK Business Awards; the Gulf Sustainability Awards and the UK Employee Experience Awards.</p>
                        </div>
                    </div>
                    <div className='h-1/2 bg-gradient-dark-transparent'></div>
                </div>
            </section>

            <section className='bg-gray-100'>
                <div>
                    <h2 className='flex justify-center bg-gradient-analagous-rose-triad-orange reveal top'>Top Stories</h2>
                </div>
                <div className='max-w-[100%] px-0 2xl:px-16 reveal top'>
                    {
                        (!data && loading) ? <></> :
                        <>
                            <Slider props={{
                                loop: true,
                                breakpoints: {
                                    1366: {
                                        slidesPerView: 3,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                    },
                                },
                                autoplay: false,
                                centeredSlides: true,
                                mode: 'light',
                                className: 'max-w-[100%] mt-16 !mx-4',
                                slideWrapperClassName: 'max-w-[100%] lg:max-w-[1182px] !h-auto !flex',
                                slideClassName: 'w-full bg-white'
                            }}>
                                {
                                        data.posts.edges.map((edge) => edge.node).map((post, i) => 
                                            <TopStory key={i} title={post.title} slug={post.slug} description={post.excerpt} image={post?.featuredImage?.node?.sourceUrl} />
                                        )
                                }                                    
                            </Slider>
                            <button className='dark self-center !mt-2'><div><Link to={`/top-stories`}>Top Stories</Link></div></button>
                        </>
                    }
                </div>
            </section>

            <section className='pb-16 2xl:pb-24'>
                <div>
                    <h2 className='winners-circle-title reveal top relative'>Winners Circle<span></span></h2>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-32 mt-8'>
                        <p className='reveal top'>Four thousand companies participated in the Awards since 2008. The <span className='bg-clip-text text-transparent bg-gradient-full'>winners circle</span> features internationally recognised brands and small businesses alike. The judging ensures winning is based on merit, not size or budget.</p>
                        <Stats props={{ className: 'reveal top delay-300' }} />
                    </div>
                </div>
            </section>
            <section className='bg-white p-0'>
                <ImageScroller props={{ folder: './images/scrolling-logos/', images: scrollingLogos }} />
            </section>

            <section className='bg-gradient-pastel text-ai-gray-900'>
                <div>
                    <div className='flex flex-col'> 
                        <h2 className='text-ai-gray-900 reveal top'>Credibility</h2>
                        <h4 className='text-ai-grey-900 font-semibold reveal top'>It is not just a claim we make it is not just what our customers tell us, our awards are externally assessed.</h4>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
                            <div>
                                <p className='reveal top'>We were awarded the Awards Trust Mark - Outstanding Ethical Awards Scheme Accreditation for our awards every year since 2015.</p>
                                <p className='reveal top'>Awards International made bespoke software to provide complete transparency of scoring and quality control of every stage of judging.</p>
                            </div>
                            <div className='flex flex-col justify-between'>
                                <p className='reveal top delay-300'>A high standard of quality means providing specification and training. Entrants and judges see the same standards, criteria and specification. Participants attend briefings by industry experts.</p>
                                
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-32 mt-32'>
                        <Testimonial props={{ title: 'Greg Melia, CXPA', description: 'Awards International and CXPA share a passion in advancing the discipline of Customer Experience by spotlighting success stories and encouraging continual improvement.', image: 'https://s3.amazonaws.com/heysummit-production/media/thumbnails/uploads/events/global-cx-day-2020/GuN3oDvw6Jc5w5oZgmz6bQ_square_medium.JPG', className: 'reveal top' }} />
                        <Testimonial props={{ title: 'Chris Robinson, Boost', description: "Awards International's events have fostered trust for years. Achieving the Independent Awards Standards Council's Awards Trust Marks is proof of the integrity, transparency, and thoroughness of the judging and entry processes.", image: 'https://assets-global.website-files.com/62f506e5f4cfc445e8ea96c0/6358d9987bda63ffdf4a2a8c_Chris%C2%A0Robinson%20(1).jpg', className: 'reveal top delay-300' }} />
                    </div>                          
                </div>
            </section>

            <section className='bg-gray-100 text-gray-900'>
                <div>
                    <div className='flex flex-col 2xl:pb-4'>
                        <h2 className='bg-gradient-analagous-rose-triad-orange self-start reveal top'>The Judge Club</h2>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-8 lg:gap-y-32'>
                        <div>
                            <h4 className='reveal top font-semibold'>offers judges the opportunity to join a community of like minded business people, to raise their profile and grow their business.</h4>
                            <p className='text-ai-grey-900 reveal top'>TJC holds many webinars and events across the year and organises networking opportunities at the awards and during the awards cycle</p>
                            <div className='reveal top'>
                                <button className='dark self-start'>
                                    <Link to={`/the-judge-club`}>
                                        <div>More Info</div>
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div>
                            <Announcement topic={'the-judge-club'} />
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-0'>
                <div>
                    <h4 className='text-ai-grey-50 reveal top'>Since the beginning our aim has always been to</h4>
                    <h2 className='self-start pt-8 reveal top'>Do Good!</h2>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-16'>
                        <div>
                            <p className='reveal top'>The very act of working to promote the interests of businesses and recognising their work is about doing good in the world.</p>
                            <p className='reveal top'>Awards International supports numerous children's charities around the world.</p>
                            <p className='reveal top delay-300'>We want to help save the planet for our children and the future of humanity. Thats why we founded the Gulf Sustainability Awards in UAE in 2015 and why we sponsor research into microplastics in the marine environment.</p>
                            {/* <div className='reveal top delay-300'>
                                <button><div>More Info</div></button>
                            </div> */}
                        </div>
                        <div className='row-span-2 reveal top flex justify-end'>
                            <img src={neil} alt='' className='w-3/4 object-contain self-end'/>
                        </div>
                        <div className='self-center reveal top ml-16'>
                            <h4 className='text-ai-grey-50'>Being a force for good in the world is for us a foundational value.</h4>
                            <p className='text-ai-gray-100 pb-8'>Neil Skehel, CEO <br/> Awards International</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

const Home = () => {
    
    return (
        <Main />
    )
}   

export default Home