import { Link } from 'react-router-dom'

const Bio = ({ props }) => {
    return (
        <div className={`bg-white/30 p-8 pt-4 flex flex-col relative ${props.className}`}>
            <div className="absolute -top-16 left-0 ml-8 grid grid-cols-[128px_auto] gap-8">
                <img className='w-32 rounded-full' src={props.image} alt='' />
            </div>
            <div>
                <p className='text-2xl font-semibold pt-20' dangerouslySetInnerHTML={{ __html: props.title }}></p>
                <p className='text-xl font-medium pt-0' dangerouslySetInnerHTML={{ __html: props.subtitle }}></p>
            </div>
            <div className='' dangerouslySetInnerHTML={{ __html: props.description }}></div>
            {
                props.link ?
                <div className='flex-1 flex mt-4 items-end'>
                    <Link to={props.link.uri} className='hover:text-ai-primary-500 transition duration-150'>{props.link.text}</Link>
                </div> : <></>
            }
        </div>
    )
}

export default Bio