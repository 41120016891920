import { gql } from '@apollo/client'

const GET_CATEGORIES_WITH_POSTS = gql`
query GetCategoriesWithPosts($first: Int!, $after: String) {
    categories {
        edges {
            node {
                id
                databaseId
                name
                posts(first: $first, after: $after, where: { orderby: { field: DATE, order: DESC } }) {
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                    edges {
                        node {
                            id title content excerpt slug date
                            featuredImage {
                                node {
                                    sourceUrl
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }
`

const GET_POSTS = gql`
    query GetPosts($first: Int!, $after: String) {
        posts(first: $first, after: $after, where: { orderby: { field: DATE, order: DESC } }) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id title content excerpt slug
                    featuredImage {
                        node {
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
`

const GET_POSTS_BY_CATEGORY = gql`
    query GetPostsByCategory ($category: String!) {
        posts( where: { categoryName: $category, orderby: { field: DATE, order: DESC } } ) {
            nodes {
                id title content excerpt
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
            }
        }
    }
`

const GET_POST_BY_SLUG = gql`
    query GetPostBySlug ($slug: ID!) {
        post( id: $slug, idType: SLUG ) {
            id title content excerpt
            featuredImage {
                node {
                    sourceUrl
                }
            }
            tags {
                edges {
                    node {
                        name posts { nodes { id title slug excerpt date featuredImage { node { sourceUrl } } } }
                    }
                }
            }
        }
    }
`

const FILTER_EVENTS = gql`
    query FilterEvents($eventtypeSlug: [String], $regionSlug: [String], $topicSlug: [String]) {
        events(where: { eventtypeSlug: $eventtypeSlug, regionSlug: $regionSlug, topicSlug: $topicSlug }, first: 1000) {
            nodes {
                title
                events {
                  hostedBy joinUs type city keyDates { keyDate keyDateLabel keyDate1 keyDateLabel1 keyDate2 keyDateLabel2 } logo { node { uri sourceUrl } } banner { node { uri sourceUrl } }
                }
                regions { nodes { name slug } }
                topics { nodes { name slug } }
                eventtypes { nodes { name slug } }
            }
        }
    }
`

const GET_EVENTS_BY_TOPIC = gql`
    query GetEventsByTopic($topicSlug: [String]) {
        events(where: { topicSlug: $topicSlug }, first: 1000) {
            nodes {
                title
                events {
                  hostedBy joinUs type keyDates { keyDate keyDateLabel keyDate1 keyDateLabel1 keyDate2 keyDateLabel2 } logo { node { uri sourceUrl } } banner { node { uri sourceUrl } }
                }
            }
        }
    }
`
const GET_REGIONS = gql`
    query GetRegions {
        regions {
            nodes { name slug }
        }
    }
`
const GET_TOPICS = gql`
    query GetTopics {
        topics {
            nodes { name slug }
        }
    }
`
const GET_EVENTTYPES = gql`
    query GetEventTypes {
        eventtypes {
            nodes { name slug }
        }
    }
`

export {
    GET_POSTS,
    GET_POSTS_BY_CATEGORY,
    GET_POST_BY_SLUG,
    GET_CATEGORIES_WITH_POSTS,
    FILTER_EVENTS,
    GET_EVENTS_BY_TOPIC,
    GET_REGIONS,
    GET_TOPICS,
    GET_EVENTTYPES
}