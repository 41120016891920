import { useEffect, useState } from 'react'

const useScrollPosition = (element) => {
    const [scrollPosition, setScrollPosition] = useState({
      position: 0,
      element: null,
      innerHeight: window.innerHeight
    })
    useEffect(() => {
      const updatePosition = () => {
        setScrollPosition({
          ...scrollPosition,
          position: window.scrollY,
          element: element?.current?.getBoundingClientRect()
        });
      }
      window.addEventListener('scroll', updatePosition)
      updatePosition()
      return () => window.removeEventListener('scroll', updatePosition)
    }, [])
  
    return scrollPosition
}
  
export default useScrollPosition