const items = [
    { title: 'Events', link: '/events' },
    { title: 'Top stories', link: '/top-stories' },
    { title: 'About us', link: '/about-us' },
    { title: 'Get started', link: '/get-started' },
    { title: 'The Judge Club', link: '/the-judge-club' },
    /* { title: 'Media', link: '/media' }, */
]

const itemsWithHome = [
    { title: 'Home', link: '/' },
    { title: 'Events', link: '/events' },
    { title: 'Top stories', link: '/top-stories' },
    { title: 'About us', link: '/about-us' },
    { title: 'Get started', link: '/get-started' },
    { title: 'The Judge Club', link: '/the-judge-club' },
    /* { title: 'Media', link: '/media' }, */
]

export default items
export { itemsWithHome }