const forms = [
    {
        "slug": "register-interest",
        "name": "Register Interest",
        "formPerma": "https://forms.zohopublic.com/awardsinternational/form/AIRegisteryourinterest/formperma/20ZfnPImmHjoXWO1Xohrk-bnQDX0lTHZxwBBEuKGNi0/records",
        "thankYouMessage": `
            <h4>Thank you for registering your interest in the Awards International programmes. </h4>
            <p>We look forward to providing you with valuable updates. In the meantime, you can explore <Link className="font-semibold border-b border-dotted border-ai-gray-900 hover:text-ai-primary-500 transition duration-300" to="/events">the upcoming events.</Link></p>
            <p>
                Kind regards,<br />
                <strong>The Awards International Team</strong>
            </p>`,
        "definition": {
            "fields": [
                {
                    "display_name": "Name",
                    "key": "Name",
                    "type": "input",
                    "variant": "group",
                    "mandatory": true,
                    "fields": [
                        {
                            "display_name": "First Name",
                            "key": "Name_First",
                            "id": "firstName",
                            "type": "input",
                            "variant": "text",
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."}
                            ],
                            "mandatory": true,
                        },
                        {
                            "display_name": "Last Name",
                            "key": "Name_Last",
                            "id": "lastName",
                            "type": "input",
                            "variant": "text",
                            "mandatory": true,
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."},
                            ],
                        }
                    ]
                },
                {
                    "display_name": "Company",
                    "key": "SingleLine",
                    "id": "company",
                    "type": "input",
                    "variant": "text",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Title",
                    "id": "title",
                    "key": "SingleLine1",
                    "type": "input",
                    "variant": "text",
                    "mandatory": false,
                },
                {
                    "display_name": "Phone",
                    "key": "PhoneNumber",
                    "id": "phone",
                    "type": "input",
                    "variant": "phone",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Email",
                    "key": "Email",
                    "id": "email",
                    "type": "input",
                    "variant": "email",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Awards Programme",
                    "key": "MultipleChoice",
                    "id": "awardsProgramme",
                    "type": "select",
                    "variant": "multiple",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                    "choices": [
                        { "value": "UK Customer Experience Awards", "key": "UK Customer Experience Awards" },
                        { "value": "European Customer Experience Awards", "key": "European Customer Experience Awards" },
                        { "value": "US Customer Experience Awards", "key": "US Customer Experience Awards" },
                        { "value": "Gulf Customer Experience Awards", "key": "Gulf Customer Experience Awards" },
                        { "value": "UK Employee Experience Awards", "key": "UK Employee Experience Awards" },
                        { "value": "UK Business Awards", "key": "UK Business Awards" },
                        { "value": "International Business Excellence Awards", "key": "International Business Excellence Awards" },
                        { "value": "International Customer Experience Awards", "key": "International Customer Experience Awards" },
                        { "value": "Turkey Customer Experience Awards", "key": "Turkey Customer Experience Awards" },
                        { "value": "Gulf Sustainability Awards", "key": "Gulf Sustainability Awards" },
                        { "value": "Saudi Customer Experience Awards", "key": "Saudi Customer Experience Awards" },
                        { "value": "South Africa Customer Experience Awards", "key": "South Africa Customer Experience Awards" }
                    ],
                },
                {
                    "display_name": "Choose Your Interest",
                    "key": "MultipleChoice1",
                    "id": "interestType",
                    "type": "select",
                    "variant": "multiple",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                    "choices": [
                        { "value": "Enter", "key": "Enter" },
                        { "value": "Judge", "key": "Judge" },
                        { "value": "Sponsor", "key": "Sponsor" },
                        { "value": "Partner", "key": "Partner" }
                    ],
                },
                {
                    "display_name": "Checkbox",
                    "description": "We are committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below:",
                    "id": "receiveNews",
                    "key": "DecisionBox1",
                    "type": "input",
                    "variant": "checkbox",
                    "choices": [
                        { "value": "I agree to receive news from the Awards International.", "key": "I agree to receive news from the Awards International." }
                    ],
                    "help" : "You can unsubscribe from this list at any time. By clicking submit below, you consent to allow Awards International to store and process the personal information submitted above to provide you with the content requested.",
                    "mandatory": false,
                },
                {
                    "key": "SingleLine2",
                    "type": "input",
                    "variant": "hidden",
                    "initialValue": "AI - Register your interest",
                    "mandatory": false,
                },
                {
                    "key": "SingleLine3",
                    "type": "input",
                    "variant": "hidden",
                    "initialValue": "AI - Register your interest",
                    "mandatory": false,
                },
                {
                    "key": "SingleLine4",
                    "type": "input",
                    "variant": "hidden",
                    "initialValue": "AI - Register your interest",
                    "mandatory": false,
                },
            ]
        }
    },
    {
        "slug": "career-with-us",
        "name": "Career with Us",
        "formPerma": "https://forms.zohopublic.com/awardsinternational/form/AICareerwithus/formperma/9Yso5-F4eoJ8NEVzY_bHZXbVzK7C7ezTUUK0hRLuOZU/records",
        "thankYouMessage": `
            <h4>Thank you for registering your interest in the Awards International careers. </h4>
            <p>We look forward to providing you with valuable updates.</p>
            <p>
                Kind regards,<br />
                <strong>The Awards International Team</strong>
            </p>`,
        "definition": {
            "fields": [
                {
                    "display_name": "Name",
                    "key": "Name",
                    "type": "input",
                    "variant": "group",
                    "mandatory": true,
                    "fields": [
                        {
                            "display_name": "First Name",
                            "key": "Name_First",
                            "id": "firstName",
                            "type": "input",
                            "variant": "text",
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."}
                            ],
                            "mandatory": true,
                        },
                        {
                            "display_name": "Last Name",
                            "key": "Name_Last",
                            "id": "lastName",
                            "type": "input",
                            "variant": "text",
                            "mandatory": true,
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."},
                            ],
                        }
                    ]
                },
                {
                    "display_name": "Email",
                    "key": "Email",
                    "id": "email",
                    "type": "input",
                    "variant": "email",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Phone",
                    "key": "PhoneNumber1",
                    "id": "phone",
                    "type": "input",
                    "variant": "text",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Choose Your Interest",
                    "key": "Dropdown",
                    "id": "interestType",
                    "type": "select",
                    "variant": "",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                    "choices": [
                        { "value": "Sales", "key": "Sales" },
                        { "value": "Marketing", "key": "Marketing" },
                        { "value": "Events", "key": "Events" },
                        { "value": "Web Development", "key": "Web Development" },
                        { "value": "Design", "key": "Design" },
                        { "value": "CXM", "key": "CXM" },
                        { "value": "CX Scoop", "key": "CX Scoop" },
                    ],
                },
                {
                    "display_name": "Checkbox",
                    "description": "We are committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below:",
                    "id": "receiveNews",
                    "key": "DecisionBox",
                    "type": "input",
                    "variant": "checkbox",
                    "choices": [
                        { "value": "I agree to receive news from the Awards International.", "key": "I agree to receive news from the Awards International." }
                    ],
                    "help" : "You can unsubscribe from this list at any time. By clicking submit below, you consent to allow Awards International to store and process the personal information submitted above to provide you with the content requested.",
                    "mandatory": false,
                },
                {
                    "key": "SingleLine",
                    "type": "input",
                    "variant": "hidden",
                    "initialValue": "AI - Career with us",
                    "mandatory": false,
                },
                {
                    "key": "SingleLine1",
                    "type": "input",
                    "variant": "hidden",
                    "initialValue": "AI - Career with us",
                    "mandatory": false,
                },
                {
                    "key": "SingleLine2",
                    "type": "input",
                    "variant": "hidden",
                    "initialValue": "AI - Career with us",
                    "mandatory": false,
                },
            ]
        }
    }
]

const fieldTypes = [ { type: 'Email', value: 3 }, { type: 'Rich Text', value: 4 }, { type: 'Number', value: 5 }, { type: 'Decimal', value: 6 }, { type: 'Percent', value: 7 }, { type: 'Currency', value: 8 }, { type: 'Auto Number', value: 9 }, { type: 'Date', value: 10 }, { type: 'Date-time', value: 11 }, { type: 'Drop Down', value: 12 }, { type: 'Radio', value: 13 }, { type: 'Multi Select', value: 14 }, { type: 'Checkbox', value: 15 }, { type: 'Lookup	12 / 13 / 14 /', value: 15 }, { type: 'Decision Box', value: 16 }, { type: 'URL', value: 17 }, { type: 'Image', value: 18 }, { type: 'File Upload', value: 19 }, { type: 'Formula', value: 20 }, { type: 'Subform', value: 21 }, { type: 'Zoho CRM', value: 22 }, { type: 'Zoho CRM Link', value: 23 }, { type: 'Add Notes', value: 24 }, { type: 'Signature', value: 25 }, { type: 'Users', value: 26 }, { type: 'Phone', value: 27 }, { type: 'Section', value: 28 }, { type: 'Name', value: 29 }, { type: 'Address', value: 30 }, { type: 'Integration', value: 31 }, { type: 'Audio', value: 32 }, { type: 'Video', value: 33 }, { type: 'Time', value: 34 }, { type: 'OCR', value: 35 }, { type: 'Object Detection', value: 36 }, { type: 'Keyword Extraction', value: 37 }, { type: 'Sentiment Analysis', value: 38 }, { type: 'Prediction', value: 39 }, ]
export default forms
export { fieldTypes }