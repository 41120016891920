/**
 * Core
 */
import { Link } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'

import ReactMarkdown from 'react-markdown'

const TopStory = ({ title, description, image, slug, excerpt=true, more=true, rounded=false }) => {
    
    return (
        <div className={`bg-white relative ${rounded ? 'rounded-2xl' : ''}`}>
            <Link to={`/top-story/${slug}`} className='block h-full pb-8'>
                <div className='w-full relative pb-[50%]'>
                    <img src={image} alt='' className={`absolute top-0 w-full h-full object-cover ${rounded ? 'rounded-t-2xl' : ''}`} />
                </div>
                
                <div className='post-excerpt'>
                    <h5 className='text-ai-gray-900 lg:min-h-[116px] lg:line-clamp-3 pb-0'>{title}</h5>
                    {
                        excerpt &&<ReactMarkdown rehypePlugins={[rehypeRaw]} className='text-ai-gray-500 pt-2 grow'>{description}</ReactMarkdown>
                    }
                    {
                        more && <span className='text-ai-gray-900 hover:text-ai-primary-500 text-sm font-semibold self-end border-b border-ai-gray-700 border-dotted'>More</span>
                    }
                </div>
            </Link>
        </div>
    )
}

export default TopStory