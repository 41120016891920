import { useState } from 'react'

const CookiesConsent = ({ callback, handleCookiesConsent }) => {

    const [settingsOn, setSettingsOn] = useState(undefined)

    const [choice, setChoice] = useState([
        { option: 'Required', value: true },
        { option: 'Analytical', tag: 'analytics_storage', value: true },
        { option: 'Marketing', tag: 'ad_storage', value: true },
        { option: 'Preferences', tag: 'personalization_storage', value: true },
    ])

    const dataLayer = window.dataLayer || [];
    const gtag = (args) => { dataLayer.push(args) }
    gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage' : 'granted',
        'personalization_storage' : 'denied',
        'security_storage' : 'granted'
    })
    const updateConsentGranted = () => {
        callback(choice.slice(1))
        handleCookiesConsent()
        //console.log(choice)
        choice.slice(1).map(consent => {
            gtag('consent', 'update', { [consent.tag]: consent.value ? 'granted' : 'denied' }) 
        }) 
    }
    const denyCookies = () => {
        callback(null)
        handleCookiesConsent()
    }
    return (

        <div className='fixed z-[9998] w-full lg:w-[60rem] bg-black lg:rounded-3xl max-lg:top-0 text-ai-gray-200 lg:bottom-0 lg:left-1/2 lg:-translate-y-8 lg:-translate-x-1/2 h-full lg:h-auto'>
            <div className='relative w-full h-full p-8 xl:p-16 overflow-auto flex flex-col'>
                <span className='close font-symbol self-end cursor-pointer' onClick={() => { denyCookies() }}>close</span>

                <h5>This website uses cookies</h5>
                <p>We use cookies to be able to provide social media features, analyse our traffic and behaviour of the visitors on our website and for marketing purposes. Sometimes we share this anonymised information to 3rd party partner companies.</p>
                <div className={settingsOn ? 'block' : 'hidden'}>
                    <p className='font-semibold mb-4'>I allow this website to use the following cookies</p>
                    {
                        choice.map((cookie, index) => {
                            const current = choice.find(o => o.option === cookie.option)
                            return (
                                <div key={index} className='flex items-center py-1'>
                                    <input
                                        type='checkbox'
                                        name={cookie.option}
                                        disabled={!index}
                                        onChange={ () => { setChoice(choice.map(o => o.option === current.option ? {...current, value: !current.value } : o)) } }
                                        after={choice[index].value ? 'check' : ''}
                                        className={`h-0 w-0 relative after:absolute after:cursor-pointer after:w-6 after:h-6 after:border mr-8 after:top-0 after:-translate-y-1/2 after:font-symbol after:content-[attr(after)] ${!index ? 'after:bg-ai-gray-800' : ''}`}
                                        />
                                    <label htmlFor={cookie.option}>{cookie.option}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex flex-col xl:flex-row xl:gap-4 items-center'>
                    <div>
                        <button onClick={() => updateConsentGranted()}><div className='!bg-black'>I accept {typeof settingsOn == 'undefined' ? 'all' : 'selected'} cookies</div></button>
                    </div>
                    <div>
                        <button onClick={() => { if(typeof settingsOn == 'undefined') setChoice(choice.map((o, index) => index ? {...o, value: false } : o)); setSettingsOn(!settingsOn)  }}><div className='!bg-black'>Change settings</div></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookiesConsent