import { ReactComponent as LogoMark } from '../assets/images/other/logo-mark.svg'
import { ReactComponent as LogoText } from '../assets/images/other/logo-txt.svg'

const Splash = () => {

    return (

        <div className="fixed z-[9999] top-0 w-screen h-screen bg-ai-gray-900 overflow-hidden flex flex-col justify-center items-center animate-splash">
            <div className='flex items-center'>
                <LogoMark className='h-24 sm:h-[200px] animate-logoMark'/>
                <LogoText className='h-24 sm:h-[200px] animate-logoText' />
            </div>            
            <div className='animate-splashText'>
                <h6 className="shimmer">Since 2008</h6>
            </div>

        </div>
    )
}

export default Splash